import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linkify',
    standalone: true,
})
export class LinkifyPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;

        const urlPattern = /(https?:\/\/[A-Za-z0-9_.!~*'()@&=+\-$/?%#;,:]+)/g;

        return value.replace(urlPattern, (url) => {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });
    }
}
