var AWS = require('../core');
var inherit = AWS.util.inherit;

/**
 * @api private
 */
AWS.Signers.RequestSigner = inherit({
  constructor: function RequestSigner(request) {
    this.request = request;
  },
  setServiceClientId: function setServiceClientId(id) {
    this.serviceClientId = id;
  },
  getServiceClientId: function getServiceClientId() {
    return this.serviceClientId;
  }
});
AWS.Signers.RequestSigner.getVersion = function getVersion(version) {
  switch (version) {
    case 'v2':
      return AWS.Signers.V2;
    case 'v3':
      return AWS.Signers.V3;
    case 's3v4':
      return AWS.Signers.V4;
    case 'v4':
      return AWS.Signers.V4;
    case 's3':
      return AWS.Signers.S3;
    case 'v3https':
      return AWS.Signers.V3Https;
  }
  throw new Error('Unknown signing version ' + version);
};
require('./v2');
require('./v3');
require('./v3https');
require('./v4');
require('./s3');
require('./presign');