<dialog class="glb-dialog" open="">
  <article class="glb-privacypolicy-article">
    <div [innerHTML]="policy | linkify"></div>
  </article>
  <ul class="glb-button-list type-center">
    <li class="glb-button-item">
      <button class="glb-text-button" type="button" (click)="closeDialog()">閉じる</button>
    </li>
  </ul>
</dialog>
