import { Platform } from '@angular/cdk/platform';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MAT_RIPPLE_GLOBAL_OPTIONS,
    MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import * as Sentry from '@sentry/angular';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DateRangePickerOverview } from './core/picker/date-picker.component';
import { LinkifyPipe } from './core/pipe/linkify.pipe';
import { CustomDateAdapter, customDateFormats } from './core/util/custom-date-adapter';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { MonshinPageComponent } from './pages/monshin-page/monshin-page.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TooltipDirective } from './pages/tooltip/tooltip.directive';
import { LaunchDarklyService } from './services/launch-darkly.service';

@NgModule({
    declarations: [
        AppComponent,
        MonshinPageComponent,
        MaintenanceComponent,
        PrivacyPolicyComponent,
        TooltipDirective,
        DateRangePickerOverview,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        TooltipDirective,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgSelectModule,
        NgxSpinnerModule,
        ToastrModule.forRoot(),
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        LinkifyPipe,
    ],
    providers: [
        provideEnvironmentNgxMask(),
        LaunchDarklyService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({}),
        },
        { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
        { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, Platform] },
        { provide: MAT_DATE_FORMATS, useValue: customDateFormats },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
